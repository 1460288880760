import React from 'react';

import { Header } from './components/includes/Header';
import Page from './components/includes/Page';

import Init from './components/screens/Init';
import Login from './components/screens/Login';
import LoginForgot from './components/screens/LoginForgot';
import Home from './components/screens/Home';
import History from './components/screens/History';
import HistoryOrder from './components/screens/HistoryOrder';
import Order from './components/screens/Order';
import OrderReview from './components/screens/OrderReview';
import Account from './components/screens/Account';
import Feedback from './components/screens/Feedback';
import Content from './components/screens/Content';
import Terms from './components/screens/Terms';


import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

export default class App extends React.Component {
	render() {
		  return (
		  	<Router>
		  		<Switch>
		  			<Route path="/" exact component={props => <Page type="Init" page={<Init {...props} />} {...props} />} />
					<Route path="/init/:id" exact component={props => <Page type="Init" page={<Init {...props} />} {...props} />} />
		  			<Route path="/login" exact component={props => <Page type="Login" page={<Login {...props} />} {...props} />} />
		  			<Route path="/forgot" exact component={props => <Page type="LoginForgot" page={<LoginForgot {...props} />} {...props} />} />
		  			<Route path="/terms" exact component={props => <Page type="Terms" page={<Terms {...props} />} {...props} />} />
		  			<Route path="/home" exact component={props => <Page type="Home" page={<Home {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
		  			<Route path="/order" exact component={props => <Page type="Order" page={<Order {...props} />} header={<Header {...props} />}footer={true} {...props} />} />
		  			<Route path="/order/review" exact component={props => <Page type="OrderReview" page={<OrderReview {...props} />} header={<Header {...props} />}footer={true} {...props} />} />
		  			<Route path="/history" exact component={props => <Page type="History" page={<History {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
		  			<Route path="/history/order/:id" exact component={props => <Page type="HistoryOrder" page={<HistoryOrder {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
		  			<Route path="/account" exact component={props => <Page type="Account" page={<Account {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
		  			<Route path="/feedback" exact component={props => <Page type="Feedback" page={<Feedback {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
					<Route path="/page/:page" exact component={props => <Page type="Content" page={<Content {...props} />} header={<Header {...props} />} footer={true} {...props} />} />
					<Route render={() => <Redirect to="/" />} /> {/* 404 redirect */}
		  		</Switch>
		    </Router>
		);
	}
}