import React from 'react';
import ContentBlock from "./ContentBlock";

export default class LoginLayout extends React.Component {
    render(){
        return (
            <div className="contents" id="login">
                <div className="login-left">
                    { this.props.content }
                </div>
                <div className="login-right">
                    <div className="content-background-wrapper">
                        <div className="contents">
                            <div className="welcome">
                                <div className="icons">
                                    <div class="icon"><img src={require('../../assets/starlink/login/Heineken-Logo.png')} /></div>
                                    <div class="icon"><img src={require('../../assets/starlink/login/Heineken-00-No-white-Background.png')} /></div>
                                    <div class="icon"><img src={require('../../assets/starlink/login/Hilbrand6.png')} /></div>
                                    <div class="icon"><img src={require('../../assets/starlink/login/HilOffTradeBrand4.png')} /></div>
                                    <div class="icon"><img src={require('../../assets/starlink/login/murphys.png')} /></div>
                                    <div class="icon"><img src={require('../../assets/starlink/login/Birra-Moretti-Logo.png')} /></div>
                                </div>  
                            </div>
                            <div className="welcome-content">
                                <div className="rollup">
                                    <div className="icon-container">
                                        <ContentBlock name="Intro 1" type="image"/>
                                    </div>
                                    <div className="description">
                                        <ContentBlock name="Intro 1"/>
                                    </div>
                                </div>

                                <div className="rollup">
                                    <div className="icon-container">
                                        <ContentBlock name="Intro 2" type="image"/>
                                    </div>
                                    <div className="description">
                                        <ContentBlock name="Intro 2"/>
                                    </div>
                                </div>

                                <div className="rollup">
                                    <div className="icon-container">
                                        <ContentBlock name="Intro 3" type="image"/>
                                    </div>
                                    <div className="description">
                                        <ContentBlock name="Intro 3"/>
                                    </div>
                                </div>

                                <div className="rollup">
                                    <div className="icon-container">
                                        <ContentBlock name="Intro 4" type="image"/>
                                    </div>
                                    <div className="description">
                                        <ContentBlock name="Intro 4"/>
                                    </div>
                                </div>
                            </div>
                            <div className="welcome-footer">
                                
                                <img alt="" src={require('../../assets/icons/enjoy-resp@2x.png')}/>
                                <a href={require('../../assets/Smartpub Terms of Service.pdf')} target="_blank">Legal</a>
                                <a href='https://www.heinekenireland.ie/Privacy-Policy/' target="_blank">Privacy</a>
                                <a href='https://www.heinekenireland.ie/Cookie-Policy/Cookie-Policy.html' target="_blank">Cookies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}